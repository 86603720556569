import * as React from 'react'
import { Composition } from 'atomic-layout';
import lz from "lzutf8";
import { Editor, Element, Frame } from '@craftjs/core'
import { useParams } from "@reach/router"
import { useQuery } from 'react-query'
import Text from '../components/UserComponents/Text'
import Video from '../components/UserComponents/Video'
import SocialIcons from '../components/UserComponents/SocialIcons'
import SocialIcon from '../components/UserComponents/SocialIcon'
import Links from '../components/UserComponents/Links'
import Link from '../components/UserComponents/Link'
import Image from '../components/UserComponents/Image'
import Divider from '../components/UserComponents/Divider'
import Products from '../components/UserComponents/Products'
import Product from '../components/UserComponents/Product'
import Promo from '../components/UserComponents/Promo'
import RootContainer from '../components/UserComponents/RootContainer';
import { useState } from 'react'
import ahoy from '../utils/ahoy/ahoy';
import RedirectErrorBoundary from '../components/RedirectErrorBoundary';
import api from '../utils/api';


const areasMobile = `
  center
`;

const areasDesktop = `
  left center right
`;


const LandingPage = () => {
  const { pageUrl } = useParams();
  const [json, setJson] = useState(null);


  const { data, isSuccess } = useQuery(
    `page-${pageUrl}`,
    () => api.landingPage(pageUrl),
    {
      onSuccess: ({ data }) => {
        const json = lz.decompress(lz.decodeBase64(data?.content));
        setJson(json);
        ahoy.default.configure({
          visitParams: {
            page_id: data.page_id,
            brand_id: data.brand_id,
          },
        });
    
        ahoy.default.start();
      },
      enabled: !!pageUrl,
    }
  )

  return isSuccess && (
    <RedirectErrorBoundary
      fallbackUrl={data?.data?.fallback_url || false}
    >
      <Composition
        width={'100%'}
        height={'100%'}
        inline
        areas={areasMobile}
        areasMd={areasDesktop}
        templateCols={'1fr'}
        templateColsMd={'1fr 1fr 1fr'}
        // padding={32}
      >
        {({ Left: LeftWrapper, Center: CenterWrapper, Right: RightWrapper }) => (
        // {({ Center: CenterWrapper }) => (
          <>
            <LeftWrapper>
            </LeftWrapper>
            <CenterWrapper
              justify='center'
              height='100vh'
              width='100%'
            >
              <Editor
                enabled={false}
                resolver={{
                  RootContainer, Text, Video, SocialIcons, SocialIcon, Link, Links, Image, Divider, Products, Product, Promo
                }}
              >
                {/* <PhoneFrame> */}
                  {json &&
                    <Frame key={'show'} data={json}>
                      <Element is={RootContainer} canvas></Element>
                    </Frame>
                  }
                {/* </PhoneFrame> */}
              </Editor>
            </CenterWrapper>
            <RightWrapper></RightWrapper>
          </>
        )}
      </Composition>
    </RedirectErrorBoundary>
  );
}

export default LandingPage
