import React from "react"
import { Router } from "@reach/router"
import LandingPage from "../client-only-pages/LandingPage"


const App = () => {
  return (
    <Router basepath="/">
      <LandingPage path=":pageUrl" />
    </Router>
  )
}

export default App